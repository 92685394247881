import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'gatsby-theme-material-ui';

import {
  Box,
  Grid,
  // Stack,
  useMediaQuery
} from '@mui/material';
import {
  Title,
  TitleBig,
  // Subtitle,
  TextContent
} from '../components/utils/utils';
import { Image } from '../hooks/image';
import { Reveal } from '../hooks/reveal';
import { Hero } from '../components/hero/Hero';
import { useStyles } from './aboutView.styles';
import { setIsScrollToServices } from '../store/appSlice';
import { useSiteMetadata } from '../hooks/useSiteMetadata';

export default function AboutView() {
  const dispatch = useDispatch();
  const { navBannerHeightDesktop } = useSelector(state => state.app);
  const { author, company } = useSiteMetadata();

  // handle sticky position 
  const [top, setTop] = useState('');
  const matches = useMediaQuery(theme => theme.breakpoints.up('md'));
  useEffect(() => {
    matches ? setTop(navBannerHeightDesktop) : setTop('unset');
  }, [matches]);

  const handleToServices = () => {
    dispatch(setIsScrollToServices(true));
  };

  return (
    <>
      <Hero images={[{
        filename: 'about1.jpg',
        alt: 'A view of the world\'s cities connected by network',
      }, {
        filename: 'about2.jpg',
        alt: 'A view of a call center office',
      }, {
        filename: 'about3.jpg',
        alt: 'A view of a high tech data servers hallway',
      }]} />
      <Box component="section" className="grey-dark">
        <Reveal>
          <TitleBig align="center">{author}, quand jeunesse rime avec expérience</TitleBig>
          {/* <Button
            to="/"
            size="large"
            color="secondary"
            sx={{ ...useStyles.button, ...useStyles.buttonService }}
            onClick={handleToServices}
            endIcon={<ChevronRightRounded />}
          >
            Découvrir nos services
          </Button> */}
        </Reveal>
      </Box>
      <Box component="section" className="about">
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            order={{ md: 1 }}
            sx={{ ...useStyles.gridHeight, ...useStyles.imageWrap, top: top }}
          >
            <Reveal className="grid fill-height">
              <Image
                className="about-image"
                filename="about_imageContent1.jpg"
                alt="A view of the piramedia tech's logo on an office wall"
              />
            </Reveal>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            order={{ md: 2 }}
            sx={{ ...useStyles.gridHeight, ...useStyles.gridText }}
          >
            <Reveal className="grid">
              <Box sx={{ ...useStyles.textWrap }}>
                <Title component="h2">Qui sommes-nous ?</Title>
                <TextContent>
                  Nous sommes une toute jeune entreprise issue du monde du CTI (Couplage Téléphonie Informatique), et qui a décidé de prendre son envol au début janvier 2022. Conscients du potentiel de notre logiciel informatique et de celui de notre téléphonie, nous avons décidé de les proposer à d’autres entreprises et de l’adapter à leurs propres besoins en créant {author}.
                  <br />
                  Qualité, simplicité, sécurité, disponibilité et le tout avec le sourire, c’est l’engagement de l’équipe de {author}.
                </TextContent>
              </Box>
            </Reveal>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            order={{ md: 4 }}
            sx={{ ...useStyles.gridHeight, ...useStyles.imageWrap, top: top }}
          >
            <Reveal className="grid fill-height">
              <Image
                className="about-image"
                alt="Cloud data computing"
                filename="about_imageContent2.jpg"
              />
            </Reveal>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            order={{ md: 3 }}
            sx={{ ...useStyles.gridHeight, ...useStyles.gridText }}
          >
            <Reveal className="grid">
              <Box sx={{ ...useStyles.textWrap }}>
                <Title component="h2">La qualité, notre priorité</Title>
                <TextContent>
                  Nous sommes certifiés ISO/CEI 27001 et 27018, ce qui nous permet de stocker des données sensibles telles que les dossiers médicaux ou les données personnelles avec le niveau le plus élevé de sécurité.
                  <br />
                  Nous prenons en charge tous les canaux de communication (appel, SMS, bip, fax ou encore e-mail).
                  <br />
                  Les technologies que nous utilisons pour la transmission des données sont full IP : tout passe par internet. Nous pouvons ainsi adapter nos technologies aux nouveaux outils de communication.
                </TextContent>
              </Box>
            </Reveal>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            order={{ md: 5 }}
            sx={{ ...useStyles.gridHeight, ...useStyles.imageWrap, top: top }}
          >
            <Reveal className="grid fill-height">
              <Image
                className="about-image"
                filename="about_imageContent3.jpg"
                alt="A view of persons talking together sitting around a table with computers"
              />
            </Reveal>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            order={{ md: 6 }}
            sx={{ ...useStyles.gridHeight, ...useStyles.gridText }}
          >
            <Reveal className="grid">
              <Box sx={{ ...useStyles.textWrap }}>
                <Title component="h2">L'adaptabilité, notre force</Title>
                <TextContent>
                  Nous avons créé et développé notre propre infrastructure, nos propres logiciels, afin de pouvoir proposer à nos clients un outil sur mesure pour répondre à leurs besoins spécifiques.
                  <br />
                  Nos offres sont personnalisables et s’adaptent à vos exigences tout en garantissant un outil sur mesure avec un très haut niveau de sécurité, nécessaire lorsqu’il s’agit de traitement de données.
                  <br />
                  Vous suivez la tendance, {author} vous accompagne. Le confort sans limite.
                </TextContent>
              </Box>
            </Reveal>
          </Grid>
          {/* <Grid
            item
            xs={12}
            md={6}
            order={{ md: 8 }}
            sx={{ ...useStyles.gridHeight, ...useStyles.imageWrap, top: top }}
          >
            <Reveal className="grid fill-height">
              <Image filename="home_hero_bg.jpg" alt="t" className="about-image" />
            </Reveal>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            order={{ md: 7 }}
            sx={{ ...useStyles.gridHeight, ...useStyles.gridText }}
          >
            <Reveal className="grid">
              <Box sx={{ ...useStyles.textWrap }}>
                <Title>Qui sommes-nous</Title>
                <TextContent>
                  Chez {author}, nous vous proposons de multiples solutions pour assurer la sécurité, la transmission et la gestion de vos données. Nos offres personnalisées s’adaptent à vos besoins et exigences. <br />Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae nam temporibus harum, nemo iste placeat quia exercitationem dolores debitis tenetur provident sint repudiandae reprehenderit aperiam quo! Cupiditate molestias unde animi.
                </TextContent>
              </Box>
            </Reveal>
          </Grid> */}
        </Grid>
      </Box>
      <Box
        component="section"
        // className="grey-light"
        sx={{ ...useStyles.buttonWrap }}
      >
        <Reveal sx={{ ...useStyles.buttonCentered }}>
          <Button
            to="/"
            size="large"
            variant="contained"
            onClick={handleToServices}
            sx={{ ...useStyles.button }}
          >
            Découvrir nos services
          </Button>
        </Reveal>
      </Box>
      {/* <Box component="section">
        <Reveal>
          <TitleBig component="h2" align="center">
            Notre équipe
          </TitleBig>
          <Grid
            container
            spacing={5}
            sx={{ ...useStyles.teamWrap }}
          >
            {company.team.map((val, i) => (
              <Grid key={i} item xs={12} sm={6}>
                <Reveal className="grid centered">
                  <Stack direction="column" justifyContent="center">
                    <Box sx={{ ...useStyles.teamImage }}>
                      <Image
                        filename={val.filename}
                        className="image-rounded"
                        alt={`Portrait of ${val.name}`}
                      />
                    </Box>
                    <Subtitle component="h3" removeMb={true}>
                      {val.name}
                    </Subtitle>
                    <TextContent>
                      <i>{val.job}</i>
                    </TextContent>
                    <TextContent>
                      {val.description}
                    </TextContent>
                  </Stack>
                </Reveal>
              </Grid>
            ))}
          </Grid>
        </Reveal>
      </Box> */}
    </>
  );
};