import { customization } from '../layout/Theme';

export const useStyles = {
  gridHeight: {
    height: {
      xs: 'unset',
      md: 435,
    },
  },

  button: {
    fontSize: theme => theme.typography.pxToRem(16),
  },

  buttonService: {
    pl: 0,
    mt: 2,
  },

  grid: {
  },

  imageWrap: {
    position: {
      xs: 'inherit',
      md: 'sticky',
    },
  },

  gridText: {
    display: 'flex',
    alignItems: {
      xs: 'flex-start',
      sm: 'center',
    },
    justifyContent: {
      xs: 'flex-start',
      sm: 'center',
    },
    zIndex: 1,
    backgroundColor: 'background.default',
  },

  textWrap: {
    p: {
      xs: `7.5vh ${customization.px.default}px`,
      sm: '10vh 10vw',
      md: 2,
    },
    maxWidth: {
      xs: '100%',
      md: 400,
    },

    '&.right-desktop': {
      textAlign: {
        xs: 'left',
        md: 'right',
      },
    },
  },

  buttonWrap: {
    pt: {
      xs: '0 !important',
      md: '10vh !important',
    },
  },

  buttonCentered: {
    textAlign: 'center',
  },

  teamWrap: {
    mt: 2,
  },

  teamImage: {
    my: 2,

    '& .image-rounded': {
      maxWidth: {
        xs: 200,
      },
    },
  },

  teamDescription: {
    // textAlign: 'center',
  },
};