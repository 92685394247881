import React from 'react';

import Seo from '../components/seo/Seo';
import AboutView from '../views/AboutView';
import { useSiteMetadata } from '../hooks/useSiteMetadata';

export default function About() {
  const { siteNavigation } = useSiteMetadata();

  return (
    <>
      <Seo
        currentTitle={siteNavigation.links[2].title}
        currentDescription={siteNavigation.links[2].description}
      />
      <AboutView />
    </>
  );
};
